import fall from "../assets/images/fall-lawn.jpg";

export const season_jobs = {
  summer: [
    {
      title: "Mowing",
      description: "",
      img: fall,
      season: "summer",
    },
    {
      title: "Sprinkler Maintenance",
      description: "",
      img: fall,
      season: "summer",
    },
    {
      title: "Weed Control",
      description: "",
      img: fall,
      season: "summer",
    },
    {
      title: "Brush Hog",
      description: "",
      img: fall,
      season: "summer",
    },
    {
      title: "Fertilization",
      description: "",
      img: fall,
      season: "summer",
    },
  ],
  fall: [
    {
      title: "Fall Leaf Cleanup",
      description:
        "",
      img: fall,
      season: "fall",
    },
    {
      title: "Winterization",
      description: "",
      img: fall,
      season: "fall",
    },
  ],
  winter: [
    {
      title: "Snow Plowing",
      description: "",
      img: fall,
      season: "winter",
    },
    {
      title: "Snow Shoveling",
      description: "",
      img: fall,
      season: "winter",
    },
    {
      title: "Sanding",
      description: "",
      img: fall,
      season: "winter",
    },
  ],
  spring: [
    {
      title: "power raking",
      description: "",
      img: fall,
      season: "spring",
    },
    {
      title: "Aeration",
      description: "",
      img: fall,
      season: "spring",
    },
    {
      title: "Fertilization",
      description: "",
      img: fall,
      season: "spring",
    },
  ],
};

export const season_description = {
  summer: "",
  winter: "",
  fall: "",
  spring: "",
};

/*

{
        title: "",
        description: "default description",
        img: fall,
        season: "summer"
    }

    */
