export default {
  //used for borders
  dark_green: "#013d13",

  //Bluish background color
  light_background: "rgb(94, 148, 148)",

  //These Colors are used for the season select segment
  summer_light: '#FAD175',
  // summer_light: '#E86070',
  spring_light: '#18D87A',
  fall_light: '#AC6C42',
  // fall_light: '#BA4082',
  winter_light: '#25B4C0',
  summer_dark: '#d4b368',
  // summer_dark: '#c64d5b',
  spring_dark: '#17c16f',
  fall_dark: '#845434',
  // fall_dark: '#a63973',
  winter_dark: '#219ca8',
  dark_blue: 'rgb(57, 98, 98)',
  darkest_blue: 'rgb(38, 66, 66)',

  //This color is for the underline in the JobItem component
  job_border: 'rgb(186, 168, 143)'
};
