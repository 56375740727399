import React from 'react';
import SeasonContainer from './SeasonContainer.js';
import tempImg1 from "../assets/images/summer-lawn.jpg"
import tempImg2 from "../assets/images/fall-lawn.jpg"
import tempImg3 from "../assets/images/winter-driveway.jpg"
import colors from "../constants/colors"

export default function Home() {
  return (
    <div style={styles.container}>
      <div style={styles.img_flex_box} className="fullWidthImgContainer">
        <img style={styles.img_third} src={tempImg1} />
        <img style={styles.img_third} src={tempImg2} />
        <img style={styles.img_third} src={tempImg3} />
      </div>
      <h3 style={styles.header_text}> No Matter the season, we have you covered!</h3>
      <SeasonContainer />
      {/* <SeasonWork season={'Summer'} image={summer} jobs={summer_jobs} />
      <SeasonWork season={'Fall'} image={fall} jobs={fall_jobs} />
      <SeasonWork season={'Winter'} image={winter} jobs={winter_jobs} /> */}
    </div>
  );
}

const styles = {
  container: {
    backgroundColor: colors.light_background,
    paddingBottom: "20px"
  },
  img_flex_box: {
    display: "flex",
    height: "200px",
    position: "relative"
  },
  img_third : {
    width: "calc(100%/3)"
  },
  header_text: {
    textAlign: "center",
    fontSize: "2em",
    padding: "15px 50px",
    textTransform: "capitalize",
    backgroundColor: colors.dark_blue,
    border: `5px solid ${colors.darkest_blue}`,
    marginBottom: "10px"
  }
}