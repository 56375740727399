import React, { useEffect } from "react";
import { useWindowResize } from "../hooks/useWindowResize";
import colors from "../constants/colors";
import logo from "../assets/images/logo_grasschopper_white.svg";

export default function Footer() {
  const width = useWindowResize().width;

  Object.keys(baseStyles).forEach((key) => {
    styles[key] = { ...baseStyles[key] };
  });

  if (width <= 1200) {
    Object.keys(midVPStyles).forEach((key) => {
      styles[key] = { ...baseStyles[key], ...midVPStyles[key] };
    });
  }

  if (width <= 650) {
    Object.keys(smallVPStyles).forEach((key) => {
      styles[key] = {
        ...baseStyles[key],
        ...midVPStyles[key],
        ...smallVPStyles[key],
      };
    });
  }

  useEffect(() => {
    if (width > 1200) {
      Object.keys(baseStyles).forEach((key) => {
        styles[key] = { ...baseStyles[key] };
      });
    }

    if (width <= 1200) {
      Object.keys(midVPStyles).forEach((key) => {
        styles[key] = { ...baseStyles[key], ...midVPStyles[key] };
      });
    }

    if (width <= 650) {
      Object.keys(smallVPStyles).forEach((key) => {
        styles[key] = {
          ...baseStyles[key],
          ...midVPStyles[key],
          ...smallVPStyles[key],
        };
      });
    }
  }, [width]);

  const contacts = [
    "Phone: 406-585-0108",
    // "Billing Phone: 406-539-0582",
    "PO Box: 6172 Bozeman, MT 59771",
    "Billing: theGrasschopperBozeman@gmail.com",
  ];

  const contactItems = contacts.map((contact) => (
    <li style={styles.contactItem}>{contact}</li>
  ));

  contactItems.push(
    <li style={styles.contactItem}>
      <a href="#" className="footerLink">
        Top
      </a>
    </li>
  );

  if (width <= 650)
    contactItems.unshift(
      <li style={styles.contactItem}>
        <img src={logo} alt="logo" style={styles.logo} />
      </li>
    );

  return (
    <div style={styles.container}>
      <ul style={styles.contactList}>{contactItems}</ul>
    </div>
  );
}
const styles = {};
const baseStyles = {
  container: {
    width: "calc(100% - 6px)",
    minHeight: "fit-content",
    backgroundColor: "black",
    color: "white",
    margin: "0px",
    padding: "0px",
    borderRadius: "0px 0px 25px 25px",
    border: `3px solid ${colors.dark_green}`,
    display: "inline-block",
  },
  contactList: {
    listStyle: "none",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: "calc(100% - 30px)",
    margin: "auto",
    padding: "13px 0px 15px 0px",
    height: "fit-content",
  },
  contactItem: {
    fontSize: "18px",
    fontWeight: "600",
    padding: "8px 10px",
  },
  logo: {
    height: "50px",
    width: "50px",
  },
};

const midVPStyles = {
  container: {
    borderRadius: "0px",
  },
};

const smallVPStyles = {
  contactList: {
    display: "block",
    margin: "auto",
    width: "fit-content",
  },
  contactItem: {
    margin: "auto",
    color: "white",
    width: "fit-content",
  },
};
