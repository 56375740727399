import React, {useEffect} from 'react';
import {useWindowResize} from '../hooks/useWindowResize';
import colors from '../constants/colors';
import {Switch, Route} from 'react-router-dom';
import Home from './Home.js';
import About from './About.js';
import Contact from './Contact.js';

export default function Body() {
  const width = useWindowResize().width;

  Object.keys(baseStyles).forEach((key) => {
    styles[key] = {...baseStyles[key]};
  });

  if (width <= 1200) {
    Object.keys(midVPStyles).forEach((key) => {
      styles[key] = {...baseStyles[key], ...midVPStyles[key]};
    });
  }

  useEffect(() => {
    if (width > 1200) {
      Object.keys(baseStyles).forEach((key) => {
        styles[key] = {...baseStyles[key]};
      });
    }

    if (width <= 1200) {
      Object.keys(midVPStyles).forEach((key) => {
        styles[key] = {...baseStyles[key], ...midVPStyles[key]};
      });
    }
  }, [width]);
  return (
    <div style={styles.container}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/About" component={About} />
        <Route path="/Contact" component={Contact} />
      </Switch>
    </div>
  );
}

const styles = {};
const baseStyles = {
  container: {
    width: 'calc(100% - 6px)',
    height: 'fit-content',
    minHeight: '600px',
    backgroundColor: '#F8F8FF',
    margin: '0px',
    padding: '0px',
    borderLeft: `3px solid ${colors.dark_green}`,
    borderRight: `3px solid ${colors.dark_green}`,
  },
};

const midVPStyles = {};
