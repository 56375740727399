import React from "react";
import tempImg from "../assets/images/fall-lawn.jpg";

export default function About() {
  return (
    <div style={styles.container}>
      <p style={styles.title} className="pageHeader">
        About
      </p>
      <div style={styles.full_img_container} className="fullWidthImgContainer">
        <img style={styles.full_img} src={tempImg} className="fullWidthImg" />
      </div>
      <div style={styles.flex_box}>
        <div style={styles.half_text}>
          <h4 style={styles.small_header}>Years of Service</h4>
          <p style={styles.half_body_para}>
            The grasschopper has been proudly serving the community for over
            three decades.
          </p>
        </div>
        <div style={styles.half_img_container} className="halfImgContainer">
          <img style={styles.half_img} src={tempImg} />
        </div>
      </div>
      {/* <div style={styles.full_img_container} className="fullWidthImgContainer">
        <img style={styles.full_img} src={tempImg} />
      </div> */}
      <p style={styles.title} className="pageHeader">
        The Owners
      </p>
      <div style={styles.flex_box}>
        <div style={styles.half_img_container} className="halfImgContainer">
          <img style={styles.half_img} src={tempImg} />
        </div>
        <div style={styles.half_text}>
          <h4 style={styles.small_header}>Rob - Owner/Operator</h4>
          <p style={styles.half_body_para}>
            Meets with clients to determine expectations, scope, and
            requirements for jobs. Whether it's the hottest day in August or the
            worst storm in winter you'll be able to catch him outside working.
          </p>
        </div>
      </div>
      <div style={styles.flex_box}>
        <div style={styles.half_text}>
          <h4 style={styles.small_header}>Sherle - Owner/Bookkeeper</h4>
          <p style={styles.half_body_para}>
            Handles billing and invoicing, start to finish. Also helps clients
            with electronic payments to save you time.
          </p>
        </div>
        <div style={styles.half_img_container} className="halfImgContainer">
          <img style={styles.half_img} src={tempImg} />
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    backgroundColor: "rgb(94 148 148)",
  },
  title: {
    margin: "auto",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "30px",
    paddingTop: "5px",
    paddingBottom: "15px",
    backgroundColor: "rgb(57, 98, 98)",
    color: "white",
    textShadow:
      "-1px 0 darkgrey, 0 1px darkgrey, 1px 0 darkgrey, 0 -1px darkgrey",
  },
  full_img: {
    width: "100%",
    height: "200px",
    //borderShadow
  },
  full_img_container: {
    width: "inherit",
    height: "200px",
    position: "relative",
  },
  half_img_container: {
    width: "50%",
    height: "250px",
    position: "relative",
  },
  flex_box: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0px 0px 0px 0px",
  },
  half_img: {
    width: "100%",
    height: "250px",
  },
  half_text: {
    width: "calc(50% - 16px)",
    paddingLeft: "8px",
    paddingRight: "8px",
    paddingTop: "10px",
  },
  half_body_para: {
    paddingTop: "10px",
  },
  small_header: {},
};
