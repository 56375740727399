import React from 'react';
import colors from '../constants/colors'

export default function JobItem({job, left}) {
  if (left) {
    return (
      <div style={{...styles.container}}>
        <img
          style={{...styles.imgContainer, ...styles.imgContainerLeft}}
          src={job.img}
        />
        <div style={{...styles.childContainer}}>
          <h4 style={{...styles.header}}>{job.title}</h4>
          <p>{job.description}</p>
        </div>
      </div>
    );
  } else {
    return (
      <div style={{...styles.container}}>
        <div style={{...styles.childContainer}}>
          <h4 style={{...styles.header}}>{job.title}</h4>
          <p>{job.description}</p>
        </div>
        <img
          style={{...styles.imgContainer, ...styles.imgContainerRight}}
          src={job.img}
        />
      </div>
    );
  }
}

const styles = {
  container: {
    width: 'calc(100% - 20px)',
    minWidth: '200px',
    display: 'flex',
    justifyContent: "space-between",
    padding: "10px",
    borderBottom: `5px solid ${colors.job_border}`
  },
  childContainer: {
    width: "calc(100% - 100px)"
  },
  imgContainer: {
    width: '80px',
    height: '80px',
  },
  header: {
    padding: '10px 10px 10px 0px',
    textTransform: 'capitalize',
  },
  imgContainerRight: {
    float: 'right',
  },
  imgContainerLeft: {
    float: 'left',
  },
};
