import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import Header from "./components/Header";
import Body from "./components/Body";
import Footer from "./components/Footer";
import { useWindowResize } from "./hooks/useWindowResize";
import background from "./assets/images/lake_mcdonald.jpg";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  const width = useWindowResize().width;

  if (width > 1200) {
    Object.keys(baseStyles).forEach((key) => {
      styles[key] = { ...baseStyles[key] };
    });
  }

  if (width <= 1200) {
    Object.keys(midVPStyles).forEach((key) => {
      styles[key] = { ...baseStyles[key], ...midVPStyles[key] };
    });
  }

  useEffect(() => {
    if (width > 1200) {
      Object.keys(baseStyles).forEach((key) => {
        styles[key] = { ...baseStyles[key] };
      });
    }

    if (width <= 1200) {
      Object.keys(midVPStyles).forEach((key) => {
        styles[key] = { ...baseStyles[key], ...midVPStyles[key] };
      });
    }
  }, [width]);

  return (
    <Router>
      <div style={styles.globalContainer}>
        <Header />
        <Body />
        <Footer />
      </div>
    </Router>
  );
}

const styles = {};

const baseStyles = {
  globalContainer: {
    margin: "0px",
    maxWidth: "1200px",
    minwidth: "350px",
    margin: "auto",
    padding: "10px calc((100vw - 1200px)/2) 15px calc((100vw - 1200px)/2)",
    // paddingLeft: 'calc((100vw - 1200px)/2)',
    // paddingRight: 'calc((100vw - 1200px)/2)',
    // paddingBottom: '15px',
    // paddingTop: '10px',
    backgroundImage: `url("${background}")`,
    backgroundSize: "100% 100%",
    // backgroundColor: '#E3DAC9', //'#FFFFF0',
  },
};

const midVPStyles = {
  globalContainer: {
    padding: "0px",
  },
};

export default App;
