import React, { useEffect } from "react";
import { useWindowResize } from "../hooks/useWindowResize";
import { withRouter, Link, useLocation } from "react-router-dom";

export default withRouter(function NavBar() {
  const width = useWindowResize().width;
  const location = useLocation();

  Object.keys(baseStyles).forEach((key) => {
    styles[key] = { ...baseStyles[key] };
  });

  if (width <= 1200) {
    Object.keys(midVPStyles).forEach((key) => {
      styles[key] = { ...baseStyles[key], ...midVPStyles[key] };
    });
  }

  if (width <= 650) {
    Object.keys(smallVPStyles).forEach((key) => {
      styles[key] = {
        ...baseStyles[key],
        ...midVPStyles[key],
        ...smallVPStyles[key],
      };
    });
  }

  useEffect(() => {
    if (width > 1200) {
      Object.keys(baseStyles).forEach((key) => {
        styles[key] = { ...baseStyles[key] };
      });
    }

    if ((width <= 1200) & (width > 650)) {
      Object.keys(midVPStyles).forEach((key) => {
        styles[key] = { ...baseStyles[key], ...midVPStyles[key] };
      });
    }

    if (width <= 650) {
      Object.keys(smallVPStyles).forEach((key) => {
        styles[key] = {
          ...baseStyles[key],
          ...midVPStyles[key],
          ...smallVPStyles[key],
        };
      });
    }
  }, [width]);

  const NavItems = [
    { name: "Home", route: "" },
    // {name: 'Contact', route: 'Contact'},
    { name: "About", route: "About" },
  ].map((item, num) => (
    <Link to={`/${item.route}`} style={styles.LinkStyle}>
      <li
        className="navItem"
        style={
          num === 2
            ? location.pathname === `/${item.route}`
              ? {
                  ...styles.navItem,
                  ...styles.lastNavItem,
                  ...styles.selectedNavItem,
                }
              : { ...styles.navItem, ...styles.lastNavItem }
            : location.pathname === `/${item.route}`
            ? { ...styles.navItem, ...styles.selectedNavItem }
            : styles.navItem
        }
      >
        {item.name}
      </li>
    </Link>
  ));
  return (
    <div>
      <ul style={styles.navList}>{NavItems}</ul>
    </div>
  );
});

const styles = {};
const baseStyles = {
  container: {},
  navList: {
    listStyle: "none",
    display: "flex",
    flexWrap: "wrap",
    width: "calc(100% + 2px)",
    // minWidth: "600px",
    margin: "0px",
    marginTop: "20px",
    padding: "16px 0px 15px 0px",
    height: "fit-content",
  },
  navItem: {
    backgroundColor: "rgb(87, 168, 87)",
    fontSize: "18px",
    fontWeight: "600",
    padding: "8px 15px",
    // backgroundColor: "",
    borderTop: "2px solid rgb(22, 70, 18)",
    borderRight: "2px solid rgb(22, 70, 18)",
    textAlign: "center",
  },
  lastNavItem: {
    // borderRadius: "0px 20px 0px 0px",
  },
  selectedNavItem: {
    // filter: 'brightness(60%)',
    color: "white",
    //   backgroundColor: "rgb(87, 168, 87)"
  },
  LinkStyle: {
    width: "calc(100% / 2)",
  },
};
const midVPStyles = {
  container: {},
};

const smallVPStyles = {
  lastNavItem: {
    borderRadius: "0px 0px 0px 0px",
  },
  navList: {
    listStyle: "none",
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    margin: "0px",
    marginTop: "20px",
    padding: "16px 0px 15px 0px",
    height: "fit-content",
  },
  LinkStyle: {
    width: "calc(100% / 3)",
  },
};
