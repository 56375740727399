import React, { useState } from "react";
import SeasonWork from "./SeasonWork";
import colors from "../constants/colors";

export default function SeasonContainer() {
  const [season, setSeason] = useState("summer");
  const season_selectors = ["summer", "spring", "winter", "fall"].map(
    (inSeason, num) => {
      return (
        <button
          style={
            season === inSeason
              ? {
                  ...styles.season_select_box,
                  ...styles.selected,
                  backgroundColor: backgroundButtonColors[inSeason],
                  // color: buttonColors[inSeason],
                  borderColor: backgroundBorderColors[inSeason],
                  borderRadius:
                    num === 0
                      ? "14px 0px 0px 0px"
                      : num === 3
                      ? "0px 0px 0px 14px"
                      : "",
                }
              : {
                  ...styles.season_select_box,
                  backgroundColor: backgroundButtonColors[inSeason],
                  color: buttonColors[inSeason],
                  borderRadius:
                    num === 0
                      ? "14px 0px 0px 0px"
                      : num === 3
                      ? "0px 0px 0px 14px"
                      : "",
                }
          }
          onClick={() => {
            document.getElementById("seasonContent").scrollTop = 0;
            setSeason(inSeason);
          }}
        >
          {inSeason}
        </button>
      );
    }
  );

  return (
    <div style={styles.container}>
      <div style={styles.season_select_container}>{season_selectors}</div>
      <SeasonWork season={season} />
    </div>
  );
}

const styles = {
  container: {
    width: "90%",
    maxWidth: "900px",
    margin: "auto",
    minWidth: "90%",
    height: "fit-content",
    display: "flex",
    border: `5px solid ${colors.dark_green}`,
    backgroundColor: "#F4ECBE",
    borderRadius: "20px",
    // overflow: "hidden"
  },
  season_select_container: {
    display: "inline-box",
    minHeight: "400px",
    // width: "20%",
    maxWidth: "100px",
    minWitdh: "80px",
  },
  season_select_box: {
    background: "transparent",
    boxShadow: "0px 0px 0px transparent",
    border: "0px solid transparent",
    textShadow: "0px 0px 0px transparent",
    height: "25%",
    width: "100px",
    textTransform: "capitalize",
    fontSize: "14px",
  },
  selected: {
    filter: "brightness(85%)",
    border: "5px solid",
    color: "white",
  },
  season: {
    width: "80%",
  },
};

const backgroundButtonColors = {
  summer: colors.summer_light,
  spring: colors.spring_light,
  fall: colors.fall_light,
  winter: colors.winter_light,
};

const backgroundBorderColors = {
  summer: colors.summer_dark,
  spring: colors.spring_dark,
  fall: colors.fall_dark,
  winter: colors.winter_dark,
};

const buttonColors = {
  summer: "black",
  spring: "black",
  fall: "black",
  winter: "black",
};
