import React from "react";
import JobItem from "./JobItem";
import colors from "../constants/colors";
import { season_jobs, season_description } from "../constants/textBodies";
import summer_img from "../assets/images/summer-lawn.jpg";
import fall_img from "../assets/images/fall-lawn.jpg";
import winter_img from "../assets/images/winter-driveway.jpg";
// import season
// import summer from "../assets/images/summer-lawn.jpg";
// import fall from "../assets/images/fall-lawn.jpg";
// import winter from "../assets/images/winter-driveway.jpg";

export default function SeasonWork({ season }) {
  const season_img =
    season === "summer"
      ? summer_img
      : season === "fall"
      ? fall_img
      : season === "winter"
      ? winter_img
      : summer_img;
  const listedJobs = season_jobs[season].map((job, count) => {
    return <JobItem job={job} left={count % 2 === 1} />;
  });
  return (
    <div style={styles.container} id="seasonContent">
      <p
        style={{
          ...styles.header,
          backgroundColor: seasonColors[season],
          borderBottom: `5px solid ${seasonBorderColors[season]}`,
        }}
      >
        {season}
      </p>
      <div style={styles.flex_box}>
        {/* <img src={season_img} style={styles.banner_image} /> */}
        <p style={styles.season_description}>{season_description[season]}</p>
      </div>
      {listedJobs}
    </div>
  );
}

const seasonColors = {
  summer: colors.summer_light,
  spring: colors.spring_light,
  fall: colors.fall_light,
  winter: colors.winter_light,
};

const seasonBorderColors = {
  summer: colors.summer_dark,
  spring: colors.spring_dark,
  fall: colors.fall_dark,
  winter: colors.winter_dark,
};

const styles = {
  banner_image: {
    height: "200px",
    width: "380px",
  },
  container: {
    width: "100%",
    minWidth: "100px",
    margin: "none",
    overflowY: "scroll",
    height: "fit-content",
    minHeight: "650px",
    borderLeft: "5px solid rgb(57, 98, 98)",
    display: "flex-box",
  },
  header: {
    fontWeight: "bold",
    fontSize: "30px",
    textTransform: "capitalize",
    textAlign: "center",
    padding: "20px",
    backgroundColor: "orange",
    color: "black",
    borderRadius: "0px 12px 0px 0px",
  },
  flex_box: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0px 0px 0px 0px",
  },
  season_description: {
    width: "calc(50%)",
    padding: "10px",
    fontSize: "1.5em",
  },
};
