import React, { useEffect } from "react";
import { useWindowResize } from "../hooks/useWindowResize";
import { withRouter, Link } from "react-router-dom";
import logo from "../assets/images/grasschopper_logo_2.svg";
import NavBar from "./NavBar";
import colors from "../constants/colors";

export default withRouter(function Header() {
  const width = useWindowResize().width;

  Object.keys(baseStyles).forEach((key) => {
    styles[key] = { ...baseStyles[key] };
  });

  if (width <= 1200) {
    Object.keys(midVPStyles).forEach((key) => {
      styles[key] = { ...baseStyles[key], ...midVPStyles[key] };
    });
  }

  useEffect(() => {
    if (width > 1200) {
      Object.keys(baseStyles).forEach((key) => {
        styles[key] = { ...baseStyles[key] };
      });
    }

    if (width <= 1200) {
      Object.keys(midVPStyles).forEach((key) => {
        styles[key] = { ...baseStyles[key], ...midVPStyles[key] };
      });
    }
  }, [width]);

  return (
    <div style={styles.container}>
      <img src={logo} alt="logo" style={styles.logo} />
      <div style={styles.headerRight}>
        <p style={styles.missionStatement}>
          <i>
            Providing quality lawn care and snow removal in Gallatin Valley
            since 1994.
          </i>
        </p>
        <NavBar />
      </div>
    </div>
  );
});

const styles = {};
const baseStyles = {
  container: {
    width: "calc(100% - 12px)",
    height: "130px",
    backgroundColor: "white",
    margin: "0px",
    padding: "0px",
    borderRadius: "15px 15px 0px 0px ",
    borderBottom: `5px solid ${colors.dark_green}`,
    borderTop: `6px solid ${colors.dark_green}`,
    borderLeft: `6px solid ${colors.dark_green}`,
    borderRight: `6px solid ${colors.dark_green}`,
    display: "flex",
  },
  logo: {
    height: "130px",
    borderRight: `3px solid ${colors.dark_green}`,
    borderRadius: "10px 0px 0px 0px",
  },
  headerRight: {
    backgroundColor: "rgb(118, 113, 113)",
    color: "white",
    display: "inline",
    height: "100%",
    width: "100%",
    overflow: "hidden",
  },
  missionStatement: {
    color: "white",
    marginTop: "15px",
    marginLeft: "15px",
    height: "40px",
    fontSize: "1.5em",
  },
};
const midVPStyles = {
  container: {
    borderRadius: "0px",
  },
  logo: {
    borderRadius: "0px",
  },
};
